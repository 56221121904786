@import '../../styles/customMediaQueries.css';

.root {
  word-break: break-word;
}

.pageRoot {
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    margin: 20px 36px 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  /* background-color: var(--matterColorNegative); Loading BG color */
  display: flex;
  flex-direction: row;

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.otherImageContainer {
  display: flex;
  margin: 0 0 0 10px;
  height: calc(0.3 * 100vw);
  max-height: 570px;
  min-height: 570px;
  width: 100%;

  & img {
    object-fit: cover;
  }
}

.otherImageContainer {

}

.otherImageContainerTwo {

}

.otherImageContainerThree {
  flex-wrap: wrap;
  justify-content: space-between;

  & img {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    max-height: 280px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.otherImageContainerFour {
  flex-wrap: wrap;
  justify-content: space-between;

  & img {
    width: calc(50% - 5px);
    max-height: 280px;
    height: 100%;

    &:nth-child(-n+2) {
      margin-bottom: 10px;
    }
    &:last-child {
      width: 100%;
    }
  }
}

.otherImageContainerFive {
  flex-wrap: wrap;
  justify-content: space-between;

  & img {
    width: calc(50% - 5px);
    max-height: 280px;
    height: 100%;

    &:last-child {
      width: calc(50% - 5px);
    }

    &:nth-child(-n+2) {
      margin-bottom: 10px;
    }
  }
}

.twoImageContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 15px 0 0;
  justify-content: space-between;
  height: 46%;
}

.rootForImage,
.singleRootForImage,
.rootForSecondImage,
.secondImage,
.rootForThirdImage,
.thirdImage,
.rootForFourthImage,
.fivethImage,
.rootForFivethImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.3 * 100vw);
    max-height: 570px;
    min-height: 570px;
    object-fit: cover;
    border-radius: var(--borderRadiusWrapper);

    &:hover {
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage,
.singleRootForImage {
  flex-basis: 100%;
  height: 100%;
  object-fit: cover;

  @media (--viewportMedium) {
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 600px);
    flex-basis: calc(100% - 600px);
    flex-shrink: 0;
    flex-grow: 1;
  }

  @media (--viewport1280) {
    max-width: calc(100% - 968px);
    flex-basis: calc(100% - 968px);
  }
}

.singleRootForImage {
  @media (--viewportLarge) {
    max-width: 100%;
  }
}

.rootForSecondImage {
  height: 50%;
}

.secondImage,
.thirdImage {
  height: 100%;
}

.rootForThirdImage,
.rootForFourthImage {
  height: auto;
  width: 48%;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 30px 24px 24px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {

  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: auto;
  top: 24px;
  right: 24px;
  margin: 0;
  padding: 8px 22px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadiusBig);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
    top: auto;
    bottom: 19px;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 144px);
    padding: 0 36px;
    margin: 0 auto 100px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 360px);
    flex-basis: calc(100% - 360px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.contentWrapperForProductLayout {
  margin: 0 0 56px 0;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    padding: 0 24px 0 24px;
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 64px 36px 0 36px;
    margin: 0 auto 100px auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 420px);
    flex-basis: calc(100% - 420px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 40px;


    flex-basis: 380px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }

  @media (--viewportLarge) {
    padding: 30px 29px;
    border: 1px solid var(--customGray);
    border-radius: var(--borderRadiusBig);
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;
  }
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}


.orderPanelTitle {
  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  display: none;
  
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--customBlack);

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (--viewportLarge) {
    display: block;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */

  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.sectionIndent {
  padding: 0;
  margin: 0 0 60px;
}

.sectionHeadingTitle {
  composes: sectionIndent;
  font-size: 30px;
  line-height: 37px;
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  composes: sectionIndent;
  composes: p from global;
  font-weight: var(--fontWeightRegular);
  word-break: break-word;
}

.textLabel {
  margin: 0 0 18px;
  padding: 0;

  font-weight: var(--fontWeightBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--customBlack);

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 32px;
  }
}

.textDescription {
  padding: 0;
  margin: 0;
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum,
.sectionAmenities,
.sectionRulesAndPolicy {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.sectionDetails {
  margin-bottom: 60px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.sectionDetailsWatersport {
  padding: 0 24px;
  margin: 0 0 60px;

  @media (--viewportMedium) {
    padding: 0;
  }


  & .detailsRow {
    margin-bottom: 0;

    @media (--viewportMedium) {
      width: 50%;
    }
  }
}

.sectionAmenities {
  composes: sectionIndent;
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationItem {
  margin-bottom: 10px;
  color: var(--customBlack);
  word-break: break-word;
}

.locationItemTitle {
  font-weight: var(--fontWeightBold)
}

.sectionReviews,
.sectionAuthor {
  padding: 0 24px;
  margin-top: 42px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.details {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  justify-content: flex-start;
  flex-direction: column;
  padding: 0;
  margin: 0;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.detailsRow {
  display: inline-flex;
  flex-direction: column;

  min-width: 158px;
  width: 100%;
  margin-bottom: 20px;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  @media (--viewportMedium) {
    margin-bottom: 40px;
    width: 25%;
  }

  & span {
    word-break: break-word;
    padding-right: 6px;
  }
}

.detailLabel {

  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 20px;

  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 24px;
  }
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 22px 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }

  & button {
    max-width: calc(100% - 75px);
    
    @media (--viewportMedium) {
    max-width: 100%;
    }
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: 360px;
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 860px;
  max-height: 360px;
  background-color: #eee;
  padding: 0;
  margin: 20px 0 20px;
  border: 0;
  cursor: pointer;

  & :global(.mapboxgl-marker) {
    background-size: contain;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.hostedBy {
  position: relative;
  margin-top: -58px;
  margin-bottom: 60px;
  border-bottom: 1px solid var(--customGray);
  z-index: 1;

  @media (--viewportMedium) {
    margin-top: -150px;
    margin-bottom: 28px;
  }
}

.hostedByBlock {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.hostedByAvatar {
  width: 96px;
  height: 96px;
  border: 6px solid var(--colorWhite);
  margin-left: 20px;


  @media (--viewportMedium) {
    margin-left: 0;
    width: 136px;
    height: 136px;
  }
}

.hostedByContent {
  padding: 14px 24px 40px;

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0 40px;
  }
}

.hostedByInfo {
  margin: 0;
  font-size: 18px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 22px;
  color: var(--customBlack);
}

.hostedByBtn {
  display: flex;
  align-items: center;
  margin-top: 20px;

  color: var(--marketplaceColor);
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightSemiBold);

  @media (--viewportMedium) {
    margin-top: 0;
  }

  &:hover {
    color: var(--marketplaceColorDark);

    & svg path {
      stroke: var(--marketplaceColorDark);
    }

    & svg circle {
      fill: var(--marketplaceColorDark);
    }
  }

  & svg {
    flex-shrink: 0;
    margin-right: 10px;
  }
}

.reviewContainer {
  display: flex;
  align-items: center;
}

.rating {
  margin-left: 10px;
  margin-right: 4px;
}

.star {
  fill: #c96156;
  height: 19px;
  margin-bottom: 6px;
  margin-right: 2px;
  padding: 0;
}

.newRental {
  max-width: 140px;
  margin: 0 0 20px auto;
  padding: 6px 10px;
  border: 2px solid var(--marketplaceColor);
  border-radius: 4px;
  background-color: var(--colorWhite);
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  text-align: center;
  line-height: 1;
  color: var(--marketplaceColor);
}
