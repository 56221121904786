@import '../../../styles/customMediaQueries.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
  word-break: break-word;
}

.field {
  margin: 25px 0 0 0;

  @media (--viewportMedium) {
    margin: 22px 0 0 0;
  }
}

.messageTip {
  composes: label from global;
  line-height: 1.5;
  padding-top: 0;

  margin: 6px 0 24px 0;
  font-weight: var(--fontWeightMedium);

  @media (--viewportMedium) {
    margin: 6px 0 24px 0;
  }
}

.error {
  color: var(--colorFail);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}
